<script>
export default {
	props: ['currentZone', 'ProductPage'],
	hookZone: ['after--images', 'bottom-inside'],
	showHook(hookZone, ProductPage) {
		return ProductPage.product.info.templateKey === 'contactLensDescription'
	},
	data() {
		return {
			currentIndex: 0,
			showDialog: false,
		}
	},
	computed: {
		showDescriptionAndAttrs() {
			return (
				(this.$b.d && this.currentZone == 'after--images') ||
				(this.$b.mt && this.currentZone == 'bottom-inside')
			)
		},
		templateKey() {
			return this.ProductPage.product.info.templateKey
		},
		templateData() {
			return this.ProductPage.product.info.templateData
		},
		attrs() {
			return this.ProductPage.product.attrs || []
		},
		hasImages() {
			return !!this.templateData.images?.length
		},
	},
	methods: {
		toggleFullScreen(i) {
			this.currentIndex = i
			this.showDialog = true
		},
	},
}
</script>

<template>
	<div>
		<template v-if="showDescriptionAndAttrs">
			<v-divider />
			<ProductPage-Attributes :attributes="attrs" class="pa-3" />
		</template>
		<div v-if="currentZone == 'bottom-inside'" class="d-flex justify-start pt-4 pb-3 pl-md-16">
			<div style="max-width: 900px">
				<div class="text-center">
					<h1 class="header font-3 text-left">{{ templateData.title }}</h1>
					<div v-if="hasImages" class="d-flex flex-wrap justify-center pa-3" style="gap: 5px">
						<Media
							v-for="(item, i) in templateData.images"
							:key="i"
							:src="item.imgSmall"
							width="200px"
							@click="toggleFullScreen(i)"
							class="media"
						/>
					</div>
				</div>
				<div v-html="templateData.description"></div>
			</div>
		</div>
		<FullScreenImage
			v-if="hasImages"
			v-model="currentIndex"
			:images="templateData.images"
			:show-dialog.sync="showDialog"
		/>
	</div>
</template>

<style scoped>
.media:hover {
	cursor: pointer;
}
</style>

<script>
export default {
	hookZone: ['actions'],
	props: {
		ProductPage: Object,
	},
	computed: {
		hasGraduationTemplate() {
			return this.ProductPage.product.info.customizationKey?.indexOf('graduationSelector') === 0
		},
		collectionMessage() {
			return this.$srv('CollectionMessage', []).filter((i) =>
				this.ProductPage.product.collectionsIds.some((id) => id == i.collectionId)
			)
		},
		importantInfoList() {
			return this.collectionMessage.flatMap((c) => c.importantInfoList)
		},
	},
}
</script>

<template>
	<div>
		<div v-if="hasGraduationTemplate"></div>
		<div v-else>
			<div v-if="collectionMessage.length">
				<div
					:class="$bem('__important-info')"
					class="pa-1 my-2 font-2"
					style="border: 1px solid #eee"
					v-for="(info, n) in importantInfoList"
					:key="n"
				>
					<div class="my-1">
						<v-icon :color="info.color" :large="$b.d">{{ info.icon }}</v-icon>
						<span :style="{ color: info.color }" class="pa-1 rounded">{{ info.title }}</span>
						<span> {{ info.description }}</span>
					</div>
				</div>
			</div>
			<ProductActions
				:product="ProductPage.product"
				:variant="ProductPage.selectedVariant"
				:customization="ProductPage.customization"
				:ctaText="ProductPage.buttonText"
				@validation="ProductPage.hookContainer().validation = $event"
				class="py-3"
			/>
		</div>
	</div>
</template>

<style></style>

<script>
import img from '@/v-shop/assets/frame.webp'

export default {
	props: ['currentZone', 'ProductPage'],
	hookZone: ['after--images', 'bottom-inside', 'bottom'],
	showHook(hookZone, ProductPage) {
		return ProductPage.product.info.templateKey === 'glassesMeasurements'
	},
	data() {
		return {
			img,
			fields: {
				crystalType: 'Tipo de cristal',
				caliber: 'Calibre',
				bridge: 'Puente',
				totalWidth: 'Ancho Total',
				totalHeight: 'Alto Total',
				templeLength: 'Largo de Patilla',
			},
		}
	},
	computed: {
		showDescriptionAndAttrs() {
			return (
				(this.$b.d && this.currentZone == 'after--images') ||
				(this.$b.mt && this.currentZone == 'bottom-inside')
			)
		},
		templateKey() {
			return this.ProductPage.product.info.templateKey
		},
		templateData() {
			return this.ProductPage.product.info.templateData
		},
		attrs() {
			return this.ProductPage.product.attrs || []
		},
		description() {
			return this.ProductPage.product.info
		},
		templateKeys() {
			return Object.keys(this.templateData).filter(
				(k) => Object.prototype.hasOwnProperty.call(this.fields, k) && this.templateData[k]
			)
		},
	},
}
</script>

<template>
	<div>
		<template v-if="showDescriptionAndAttrs">
			<v-divider />
			<ProductPage-Description :templateData="description" class="pa-3" />
			<ProductPage-Attributes :attributes="attrs" class="pa-3" />
		</template>
		<div v-if="currentZone == 'bottom-inside'" class="d-flex justify-start pt-4 pb-3 pl-md-8">
			<div style="max-width: 900px">
				<div class="text-center">
					<h1 class="header font-3 text-left">Características del Anteojo</h1>
					<Media :src="img" width="100%" as="img" />
				</div>

				<div class="font-2" :class="$b({ d: 'd-flex justify-center', m: 'pl-3' })" style="gap: 30px">
					<p v-for="key in templateKeys" :key="key">
						<span class="font-weight-bold">{{ fields[key] }}</span
						>: {{ templateData[key] }}
					</p>
				</div>

				<div v-if="templateData.description">
					<div class="py-6">
						<v-divider class="mr-auto ml-auto" style="max-width: 400px" />
					</div>
					<div v-html="templateData.description"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.pre-line {
	white-space: pre-line;
}
</style>
